
// Ping Poet Tetradic Color Scheme
$color_dogwood_rose: #D11363;
$color_fulvous_orange: #D28314;
$color_emrald_green: #14d283;
$color_flickr_blue: #1463d2;

$color_scheme_primary: $color_dogwood_rose;
$color_scheme_secondary: $color_fulvous_orange; 

// customize bootstrap theme colors
$primary: $color_scheme_primary;
$secondary: $color_scheme_secondary;

// Import all of Bootstrap's CSS
// https://getbootstrap.com/docs/5.2/customize/sass/#importing
@import "~bootstrap/scss/bootstrap";

/* this is a reusable way to put a nice mark before a unordered list */
.icon-list li::before {
  display: block;
  flex-shrink: 0;
  width: 1.5em;
  height: 1.5em;
  margin-right: .5rem;
  content: "";
  // for the colored svg see https://stackoverflow.com/a/46904983/51061
  background-color: $primary;
  mask: url("../images/arrow-right-circle-fill.svg") no-repeat 50% 50%;
}
